export async function getAllTestimonials() {
  try {
    const url = '/testimonials/';
    const cacheKey = `testimonials`;
    const value = await useChFetch<any>(cacheKey, url, undefined, 30000 * 60);

    return value;
  } catch (e) {
    throw e;
  }
}
